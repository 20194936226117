




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ContactMoodboard',
  props: {
    picture: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
})
